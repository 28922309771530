import React from "react"
import QuizMaster from "../../../components/layouts/quiz_master"
import Settings from "../../../components/quiz/settings"
import ToggleList from "../../../components/quiz/toggle-list"
import Toggle from "../../../components/quiz/toggle"
import Time from "../../../components/japanese/other/time.js"

class TimePage extends React.Component  {
  constructor(props) {
    super(props);
    this.hour = 0;
    this.minute = 0;
    this.ampm = 0;
    this.lastminute = 0;
    this.answer = "";
    this.ampmSetting = false;
    this.correct = 0;
    this.incorrect = 0;
    this.newTime = this.newTime.bind(this);
    this.checkAnswer = this.checkAnswer.bind(this);
    this.state = {
        loaded: false,
        hour: this.hour,
        minute: this.minute,
        ampm: this.ampm,
        lastminute: this.lastminute,
        question: "",
        lastAnswer: "",
        status: "",
        wait: false,
        correct: this.correct,
        incorrect: this.incorrect,
    };
  }

  newTime() {
    this.minute = QuizMaster.generateItem(function(){return Math.floor(Math.random() * 60);}, this.lastminute)
		this.hour = Math.floor(Math.random() * 12) + 1
    this.ampm = this.ampmSetting ? Math.floor(Math.random() * 2) + 1 : 0;

    this.lastminute = this.minute;
    let status = "";
    this.setState({ hour: this.hour, minute: this.minute, ampm: this.ampm, lastminute: this.lastminute, wait: false, status: status});
    this.updateQuestion()
    this.updateAnswer()
  }

  updateQuestion() {
    const eng_ampm = ["", " A.M.", " P.M."];
    var minstr = this.minute.toString()
    if (minstr.length === 1) {minstr = "0" + minstr}
    var question = this.hour.toString() + ":" + minstr + eng_ampm[this.ampm]
    this.setState({question: question})
  }

  updateAnswer() {
    this.answer = Time.getAnswer(this.hour, this.minute, this.ampm);
  }

  checkAnswer(userAnswer) {
    var status = "";
    if(this.answer.includes(userAnswer)) {
      status = "correct";
      this.correct += 1;
    } else {
      status = "incorrect";
      this.incorrect += 1;
    }
    let lastAnswer = this.answer.join(" or ");
    this.setState({status: status, correct: this.correct, incorrect: this.incorrect, lastAnswer: lastAnswer, wait: true})
  }

  ampmToggle = e => {
    this.ampmSetting = !this.ampmSetting;
    this.setState({ ampmSetting: this.ampmSetting });
    this.newTime();
  }

  componentDidMount() {
    if(!this.state.loaded) {
      this.newTime()
      this.setState({ loaded: true });
    }
  }

  render() {
    return (
      <QuizMaster
        title="Japanese Time"
        description="Interactive Study tool for learning Japanese time, learn how to tell time in Japanese and practice hour and minute counters."
        question={this.state.question}
        wait={this.state.wait}
        status={this.state.status}
        onSubmit={this.checkAnswer}
        onClear={this.newTime}
        correct={this.state.correct}
        incorrect={this.state.incorrect}
        answer={this.state.lastAnswer}
        settings={
          <Settings>
            <ToggleList>
              <Toggle id="ampmsetting" label="AM / PM" checked={this.state.ampmSetting} onToggle={this.ampmToggle} />
            </ToggleList>
          </Settings>
        }
      >
      </QuizMaster>
    );
  }
}

export default TimePage
