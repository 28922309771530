import React from "react"
import styled from "styled-components"

const ToggleList = styled.ul`
  text-align: center;
  align-items: center;
  margin: 0;
`

export default ({children}) => (
  <ToggleList>
      {React.Children.map(children, (child, i) => {
        if ((i+1) % 4 === 0) return <>{child}<div></div></>
        return child
      })}
  </ToggleList>
)
